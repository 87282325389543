.container {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  border-top: 1px solid #e0e0e0;
  background-color: #fff;
  padding: 12px 0px;
}

.iconContainer {
  width: 24px;
  height: 24px;

  stroke: black;
  fill: black;

  &:active {
    transform: scale(0.9);
    transition: transform 0.1s ease-out;
  }
}
