.tag {
  display: inline-block;
  padding: 8px 8px;
  border-radius: 8px;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:hover {
    background-color: #f5f5f5;
    color: #000;
  }
}
