.list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.item {
  &:hover {
    text-decoration: underline;
  }
}
