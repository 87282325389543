/*
  https://github.com/timlrx/rehype-prism-plus#styling
*/

/**
 * Inspired by gatsby remark prism - https://www.gatsbyjs.com/plugins/gatsby-remark-prismjs/
 * 1. Make the element just wide enough to fit its content.
 * 2. Always fill the visible space in .code-highlight.
 */
.code-highlight {
  float: left; /* 1 */
  min-width: 100%; /* 2 */
  max-width: 500px;
}

@media (max-width: 600px) {
  .code-highlight {
    width: 300px;
    /* width: 100%; */
  }
}

.code-line {
  display: block;
  padding-left: 16px;
  padding-right: 16px;
  /* margin-left: -16px; */
  margin-right: -16px;
  border-left-width: 4px;
  border-left-color: rgba(31, 41, 55, 0); /* Set code block color */
}

.code-line.inserted {
  background-color: rgba(16, 185, 129, 0.2); /* Set inserted line (+) color */
}

.code-line.deleted {
  background-color: rgba(239, 68, 68, 0.2); /* Set deleted line (-) color */
}

.highlight-line {
  margin-left: -16px;
  margin-right: -16px;
  background-color: rgba(55, 65, 81, 0.5); /* Set highlight bg color */
  border-left-width: 4px;
  border-left-color: rgb(59, 130, 246); /* Set highlight accent border color */
}

.line-number::before {
  display: inline-block;
  width: 1rem;
  text-align: right;
  margin-right: 16px;
  margin-left: -8px;
  color: rgb(156, 163, 175); /* Line number color */
  content: attr(line);
}
