.link {
  color: #0f83fd;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.archiveListContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.archiveListHeader {
  font-weight: bold;
}
.faceIcon {
  border-radius: 100%;
  width: 48px;
  height: 48px;
  &:hover {
    transform: scale(1.05);
  }
}
.iconContainer {
  width: 30px;
  height: 30px;

  &:hover {
    color: rgb(82 82 91);
    transform: scale(1.05);
  }
}
.policyContainer {
  padding: 32px 0px;
}
.policyLink {
  color: rgb(100 116 139);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
